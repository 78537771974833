import React, {useCallback, useEffect, useState, useContext} from 'react';
import {
    Sidebar, 
    Menu, 
    MenuItem, 
    SubMenu, 
    SidebarHeader, 
    //SidebarContent 
} from 'react-pro-sidebar';

//import 'react-pro-sidebar/dist/css/styles.css';
import {getFromLocalStorage, setLocalStorage, removeItem} from "../../utils/local-storage";
import makeRequest from "../../utils/fetch-request";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PerfectScrollbar from 'react-perfect-scrollbar';

import 'react-perfect-scrollbar/dist/css/styles.css';
import {Context} from '../../../context/store';
import LiveSideBar from '../live-sidebar';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import gameCategories from '../../utils/static-data';
import Notify from '../../utils/Notify';
import CasinoSidebar from '../../pages/casino/casino-sidebar';

const ProSidebar = (props) => {

    const [collapsed, setCollapsed] = useState(false)
    const [toggled, setToggled] = useState(false)
    const [sport, setSport] = useState(79)
    const [loc, setLoc] = useState("/");
    const [state, dispatch] = useContext(Context);
    const location = useLocation()
    const [searchParams, ] = useSearchParams();
    const queryParamValue = searchParams.get('id');
    const [competitions, setCompetitions] = useState(null);
    const [focusSportId, setFocusSportId] = useState(null);
    const excludeSidebar = ["/login", "/signup", '/withdraw', "/deposit"]
    

    useEffect(() => {
        setLoc(location?.pathname)
    }, [location])

    const handleToggleSidebar = (value) => {
        setToggled(value);
    };

    const changeMatches = (gameType, competition) => {
        
        setLocalStorage('active_item', competition.sport_id);
        if(gameType == "competition") {
            dispatch({type: "SET", key: "filtercompetition", payload: {competition_id: competition?.competition_id}})
        }
    }

    const fetchData = async() => {

        
        // get all categories
        let endpoint2 = "/v2/sports";
        let cached_competitions = getFromLocalStorage('categories');
        if (!cached_competitions || cached_competitions.length < 1) {
            const [competition_result] = await Promise.all([
                makeRequest({url: endpoint2, method: "get", api_version:2}),
            ]);
            let [c_status, c_result] = competition_result;
            if (c_status == 200) {
                setCompetitions(c_result?.data);
                setLocalStorage('categories', c_result?.data, 5 * 60 * 1000);
                dispatch({type:"SET", key:"categories", payload:c_result});
            }
            //  else {
            //     Notify({status: 400, message: "Sport categories not found"});
            // }
        } else {
            setCompetitions(cached_competitions);
            dispatch({type:"SET", key:"categories", payload:cached_competitions});
        }

        setFocusSportId(79);
    };

    useEffect(() => {
        if (!state?.nosports) {
            fetchData();
        }
        const abortController = new AbortController();

        return () => {
            abortController.abort();
        };
    }, []);

    const [width, setWidth] = useState(window.innerWidth);
    
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        if (width >= 768 && width <= 991) {
            setCollapsed(true)
        } else {
            setCollapsed(false)
        }
    }
    const updateSidebarState = () => {
        let sport_id = (new URL(window.location.href).searchParams.get('sport_id'))
        if (sport_id == null && window.location.pathname == '/') {
            sport_id = 79
        }
        setSport(sport_id)
    }

    const getActiveSport = (matchId) => {
        return (Number(sport) == Number(matchId))

    }
    
    useEffect(() => {
        updateDimensions()
        updateSidebarState()
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [width]);

    const getSportImageIcon = (sport_name, folder = 'svg', topLeagues = false) => {

        let default_img = 'sure'
        let sport_image;
        try {
            sport_image = topLeagues ? require(`../../../assets${sport_name}`) : require(`../../../assets/${folder}/${sport_name}.svg`);
        } catch (error) {
            sport_image = require(`../../../assets/${folder}/${default_img}.png`);
        }
        return sport_image
    }

    const getDefaultMarketsForSport = (competition) => {
        return competition?.default_display_markets
    }

    const getSportCompetitions = async () => {

        let endpoint = `/v2/sports/competitions/${focusSportId}`;
        let cached_competitions = getFromLocalStorage('categories') || state?.categories;

        // get item whose id is equal the id
        
        let sport = competitions?.find(obj => obj?.sport_id == focusSportId);
        // check if the sport has competitions
        let hasCompetitions = sport && Object.keys(sport).includes('competitions');

        // Only make request for a focussport if the competitions are not existent
        if (!hasCompetitions){
            makeRequest({url: endpoint, method: 'GET', api_version:2}).then(([status, response]) => {
                let sportIndex = competitions?.findIndex(obj => obj?.sport_id == focusSportId);
                // update the sport with competitions
                if (sport && status == 200) {
                    sport.competitions = response?.data?.items
                    
                    // for now, we just want to set the top competitions as soccer. later we'll set for every sport,
                    // so, on the basis of current situation
                    // if it's soccer, we take a slice of the games and set to top soccer
                    
                    if (focusSportId == 79) {
                        dispatch({type:"SET", key:"topcompetitions", payload:sport.competitions});
                        setLocalStorage("topcompetitions", sport.competitions, 5 * 60 * 1000);
                    }
                    let newCompetitions = competitions;

                    // get new competitions with the updated stuff
                    newCompetitions[sportIndex] = sport;

                    // update the state and the localStorage
                    setCompetitions(newCompetitions);
                    dispatch({type: "SET", key: "categories", payload: newCompetitions})
                    setLocalStorage("categories", newCompetitions, 5 * 60 * 1000)

                }
                 
            })
        }
    }

    // comment for normal games
    useEffect(() => {dispatch({type: "SET", key: "nosports", payload: true})}, [])

    const getSportCategories = () => {
        // Check for and update for categories
        let endpoint = "/v2/sports/categories/" + focusSportId
        let sport = competitions?.find(obj => obj?.sport_id == focusSportId);
        let hasCategories = Object.keys(sport).includes('categories');
        if (!hasCategories){
            makeRequest({url: endpoint, method: 'GET', api_version:2}).then(([status, response]) => {
                let sportIndex = competitions?.findIndex(obj => obj?.sport_id == focusSportId);
                // update the sport with competitions
                if (status == 200) {
                    sport.categories = response?.data[0].categories
                    let newCompetitions = competitions;

                    // get new competitions with the updated stuff
                    newCompetitions[sportIndex] = sport;
                    setCompetitions(newCompetitions);
                    dispatch({type: "SET", key: "categories", payload: newCompetitions})
                    setLocalStorage("categories", newCompetitions, 5 * 60 * 1000)

                } else {
                    sport.categories = [];
                }
                 
            })
        }
    }


    useEffect(() => {
        if(competitions !== null ) {
            getSportCompetitions();
        }
    }, [focusSportId])


    return (
        <>
        {(!state?.nosports) && (loc.includes("/live") ? <LiveSideBar /> : 
            <div style={{
                display: 'flex',
                overflow: 'scroll initial',
                zIndex: 10,
                top: "100px"
            }}
                 className={`vh-100 sticky-top d-none d-md-block up col-md-2`}>
                    {!competitions &&
                    <Sidebar
                    style={{backgroundColor: '#16202c !important'}}
                    image={false}
                    onToggle={handleToggleSidebar}
                    collapsed={collapsed}
                    toggled={toggled}>
                        <Menu iconShape="circle">
                            {gameCategories?.map((competition, index) => (
                                    <SubMenu title={competition.sport_name} defaultOpen={competition.sport_name == "Soccer"}
                                        icon={<img style={{borderRadius: '50%', height: '30px'}}
                                                    src={getSportImageIcon(competition.sport_name)} alt=''/>}
                                        label={competition.sport_name}
                                        className={`${['bandy','pesapallo', 'dota 2', 'starcraft', 'gaelic football', 'gaelic hurling', 'gaelic football'].includes(competition?.sport_name?.toLowerCase()) && 'force-reduce-img'}`}
                                        key={index}>
                                {/* <SubMenu title={'Countries'}
                                             style={{maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden'}}> */}
                                        <PerfectScrollbar >
                                        
                                        {/* For soccer, let's have the top soccer leagues here as well */}
                                        {
                                            competition.sport_name =="Soccer"
                                            &&
                                            competitions?.top_soccer?.map ((league, idx) => (
                                                <MenuItem
                                                    title={league.competition_name}
                                                    // label = {country.category_name}
                                                    key={league?.competition_id}
                                                    icon = {<img style={{borderRadius: '1px', height: '13px', width:"13px" }}
                                                    src={getSportImageIcon(league?.flag, 'img/flags-1-1', true)}
                                                    alt='' className='inline-block mr-2'/>}
                                                    >
                                                        <Link className={`sidebar-link ${(queryParamValue == league.competition_id) && 'active'}`} to={`/sports/competition/matches?id=${league.competition_id}`}
                                                            onClick={() => changeMatches("competition", league)}>
                                                            <span>{league?.competition_name}</span>
                                                        </Link>
                                                </MenuItem>
                                            ))
                                        
                                        }
                                        
                                        </PerfectScrollbar >
                                { /* </SubMenu> */}
                                </SubMenu>
                            ))}
                        </Menu>
                </Sidebar>
                }



                {/* The new  */}

                <Sidebar
                    style={{backgroundColor: '#16202c !important'}}
                    image={false}
                    onToggle={handleToggleSidebar}
                    collapsed={collapsed}
                    toggled={toggled}>
                        <Menu iconShape="circle">
                            {
                                competitions?.map((sport, idx) => (
                                    <div onClick={() => setFocusSportId(sport.sport_id)}>
                                    <SubMenu title={sport?.sport_name} defaultOpen={sport?.sport_name == "Soccer"}
                                        icon={<img style={{borderRadius: '50%', height: '30px'}}
                                                    src={getSportImageIcon(sport?.sport_name)} alt=''/>}
                                        label={sport?.sport_name}
                                        className={`${['bandy','pesapallo', 'dota 2', 'starcraft', 'gaelic football', 'gaelic hurling', 'gaelic football'].includes(sport?.sport_name?.toLowerCase()) && 'force-reduce-img'}`}
                                        key={idx}>
                                {/* <SubMenu title={'Countries'}
                                             style={{maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden'}}> */}
                                        <PerfectScrollbar >
                                        
                                        {/* For soccer, let's have the top soccer leagues here as well */}
                                        
                                        {
                                            sport?.sport_id == focusSportId &&
                                            sport?.competitions?.map ((competition, idx) => (
                                                <MenuItem
                                                    title={competition?.competition_name}
                                                    // label = {country.category_name}
                                                    key={competition?.competition_id}
                                                    icon = {<img style={{borderRadius: '1px', height: '13px', width:"13px" }}
                                                    src={getSportImageIcon(competition?.flag, 'img/flags-1-1', true)}
                                                    alt='' className='inline-block mr-2'/>}
                                                    >
                                                        <Link className={`sidebar-link ${(queryParamValue == competition.competition_id) && 'active'}`} to={`/sports/competition/matches?id=${competition.competition_id}`}
                                                            onClick={() => changeMatches("competition", competition)}>
                                                            <span>{competition?.competition_name}</span>
                                                        </Link>
                                                </MenuItem>
                                            ))
                                        
                                        }
                                            <div 
                                            onClick={() => getSportCategories() }
                                            ><SubMenu
                                                title = "Countries"
                                                label = "Countries"
                                                icon={<img style={{borderRadius: '50%', height: '15px'}}
                                                className=''
                                                src={getSportImageIcon("more", 'img/flags-1-1')}
                                                alt=''
                                                />}

                                            >
                                                {sport?.sport_id == focusSportId && 
                                            sport?.categories?.map((category, idx) => (
                                                <SubMenu
                                                        title={category?.category_name}
                                                        label = {category?.category_name}
                                                        icon={<img style={{borderRadius: '50%', height: '15px'}}
                                                        className=''
                                                        src={getSportImageIcon(category?.cat_flag, 'img/flags-1-1')}
                                                        alt=''/>}
                                                        className='inner-submenu'
                                                        key={idx}>

                                                            {category?.competitions?.map((league, leagueKey) => (
                                                                <MenuItem
                                                                title={league.competition_name}
                                                                // label = {country.category_name}
                                                                key={league?.competition_id}
                                                                >
                                                                    <Link className='country-competition-item' to={`/competition/country/league/${league.competition_id}/all`}
                                                                        onClick={() => changeMatches("competition", league)}>
                                                                        <span>{league?.competition_name}</span>
                                                                    </Link>
                                                                </MenuItem>
                                                            ))                                                                
                                                            }
                                                            
                                                </SubMenu>))}
                                            </SubMenu>
                                            </div>
                                        
                                        </PerfectScrollbar >
                                { /* </SubMenu> */}
                                </SubMenu>
                                </div>
                                ))
                            }
                        </Menu>
                </Sidebar>
                
            </div>

            )

        }
        
        {
            !excludeSidebar.includes(location.pathname)  && 
            
            <div className={`d-none d-md-block col-md-2`}>
                <div className='bg-white'>
                    {location.pathname == "/signup" && "signup"}
                   <CasinoSidebar categories={state?.casinogames?.gameTypes} providers={state?.casinogames?.providers}/>
                </div>
            </div>
        }
        </>
        
    );
};

export default ProSidebar;
